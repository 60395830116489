<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card
      :title="$t('bonus-history')"
    >
      <b-row align-h="center">
        <b-col cols="12">
          <b-table
            :items="items"
            :fields="fields"
          />
        </b-col>
        <b-col cols="auto">
          <b-pagination
            align="fill"
            size="sm"
            class="mb-1"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BTable,
  BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BTable,
    BPagination,

  },
  data() {
    return {
      show: false,
      items: [],
    }
  },
  computed: {
    fields() {
      return [
        {
          label: this.$t('amount'),
          key: 'amount',
        },
        {
          label: this.$t('type'),
          key: 'type',
        },
        {
          label: this.$t('account-id'),
          key: 'network',
        },

        {
          label: this.$t('deposit-id'),
          key: 'created_at',
        },
        {
          label: this.$t('date'),
          key: 'date',
        },
      ]
    },

  },
}
</script>
